import React from 'react'
import styles from './HashtagItemsWithRemove.module.css'

const HashtagItemsWithRemove = ({ hashtags, onRemoveHashtag }) => {
  return (
    <ul className={styles.hashtagList}>
      {hashtags.map((hashtag) => (
        <li key={hashtag.id} className={styles.hashtagItem}>
          #{hashtag.name}
          <span
            onClick={() => onRemoveHashtag(hashtag.id)}
            className={styles.hashtagDelete}
          >
            x
          </span>
        </li>
      ))}
    </ul>
  )
}

export default HashtagItemsWithRemove
