import React, { useState } from 'react'
import ModalHashtag from './ModalHashtag'
import styles from './EditItemName.module.css'
import penIcon from '../assets/pen_icon.svg'
import axios from '../api/axios'

const EditItemName = ({ item, updateItem }) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [name, setName] = useState(item.name)
  const [name2, setName2] = useState(item.name2)

  const handleEditClick = () => {
    setShowEditModal(true)
    setName(item.name)
    setName2(item.name2)
  }
  const handleEditChange = (e) => {
    setName(e.target.value)
  }

  const handleEditChange2 = (e) => {
    setName2(e.target.value)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    const updatedData = {
      name,
      name2,
      uuid: item.uuid
    }
    try {
      const response = await axios.put(`/api/admin/v1/learn/direction?cacheBust=${new Date().getTime()}`, updatedData)
      updateItem(response.data)
      setShowEditModal(false)
    } catch (error) {
      alert('Ошибка при отправке формы. ', error)
    }
  }

  return (
    <>
      <img
        draggable={false}
        className={styles.pointer}
        onDragStart={(e) => e.stopPropagation()}
        onClick={handleEditClick}
        src={penIcon}
        alt="Edit item name"
      />

      {showEditModal && (
        <ModalHashtag
          draggable={false}
          onDragStart={(e) => e.stopPropagation()}
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          item={item}
        >
          <form className={styles.categoryForm} onSubmit={handleEditSubmit}>
            <input
              className={styles.listInput}
              type="text"
              name="name"
              value={name}
              onChange={handleEditChange}
              placeholder={ 'Введите ' + (item.type === 'grade' ? 'класс' : item.type === 'subject' ? 'предмет ' : item.type === 'chapter' ? 'главу ' : 'тему ') }
              required
            />
            {item.type === 'topic' && (<input
              className={styles.listInput}
              type="text"
              name="name2"
              value={name2}
              onChange={handleEditChange2}
              placeholder={'Тема регионального компонента'}
              required
            />)}
            <button className={styles.formSaveConfirmBtn} type="submit">
              Сохранить
            </button>
          </form>
        </ModalHashtag>
      )}
    </>
  )
}

export default EditItemName
