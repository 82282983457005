import React, { useEffect, useState } from 'react'
import axios from '../api/axios'
import styles from './VideoEdit.module.css'
import HashtagSelector from './HashtagSelector'
import HashtagItemsWithRemove from './hashtag/HashtagItemsWithRemove'

const VideoEdit = ({ videoId, currentTitle, currentDescription, fetchVideos, onClose }) => {
  const [title, setTitle] = useState(currentTitle)
  const [hashtags, setHashtags] = useState([])
  const [description, setDescription] = useState(currentDescription)
  const [showHashtagSelector, setShowHashtagSelector] = useState(false)

  const toggleHashtagSelector = () => {
    setShowHashtagSelector(!showHashtagSelector)
  }

  const fetchHashtags = async () => {
    try {
      const hashtagResponse = await axios.get(`/api/admin/v1/tag/video/${videoId}`)
      setHashtags(hashtagResponse.data.tags || [])
    } catch (err) {
      alert('Error fetching hashtags: ', err)
      // setError('Failed to load hashtags for video')
    }
  }

  useEffect(() => {
    fetchHashtags()
  }, [videoId])

  const handleSelectHashtag = async (selectedHashtag) => {
    try {
      const response = await axios.put(`/api/admin/v1/tag/video/${videoId}/${selectedHashtag.id}`)
      if (response.status === 200) {
        // setHashtags([...hashtags, selectedHashtag]);
        fetchHashtags()
      }
    } catch (error) {
      alert('Error adding hashtag: ', error)
    }
  }

  const handleHashtagDelete = async (hashtagId) => {
    try {
      const response = await axios.delete(`/api/admin/v1/tag/video/${videoId}/${hashtagId}`)
      if (response.status === 200) {
        setHashtags(currentHashtags => currentHashtags.filter(tag => tag.tag_id !== hashtagId))
      }
    } catch (error) {
      alert('Error deleting hashtag: ', error.response || error)
      // setError(error.response ? error.response.data.message : 'Failed to delete hashtag')
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const formData = new FormData()
      formData.append('id', videoId)
      formData.append('title', title)
      formData.append('description', description)

      const response = await axios.put(`api/admin/v1/content/facts/${videoId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      if (response.status === 200) {
        console.log('Video updated successfully')
        onClose()
        fetchVideos()
      }
    } catch (error) {
      alert('Error updating video: ', error)
    }
  }

  return (
        <div className={styles.videoEditContainer}>
            <form className={styles.videoEditContainerForm} onSubmit={handleSubmit}>
                <label htmlFor="title">Заголовок:* </label>
                <input
                    id="title"
                    pattern="^(?! ).*$"
                    maxLength={60}
                    required
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />

                <label htmlFor="description">Описание: </label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <button type="submit"className={styles.saveBtn}>Сохранить</button>
            </form>
            <div className={styles.hashtagsContainer}>
                <HashtagItemsWithRemove
                  hashtags={hashtags.map(hashtag => ({
                    ...hashtag,
                    id: hashtag.tag_id,
                    name: hashtag.tag_name
                  }))}
                  onRemoveHashtag={handleHashtagDelete}
                  />
                <div className={styles.addHashtagContainer}>
                    <span
                        onClick={toggleHashtagSelector}
                        className={styles.addHashtag}
                    >Добавить+</span>
                    {showHashtagSelector && <HashtagSelector onSelectHashtag={handleSelectHashtag} />}
                </div>
            </div>
        </div>
  )
}

export default VideoEdit
