/*eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "../api/axios";
import ModalHashtag from "../components/ModalHashtag";
import styles from "./EducationalContent.module.css";
import ConfirmationModal from "../components/ConfirmModal";
import Lesson from "../components/Lesson";
import EditItemName from "../components/EditItemName";
import ticketIcon from '../assets/ticket.svg';


// import deleteIcon from '../assets/delete_icon.svg';

const EducationalContent = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    name2: "",
    type: "",
    weight: 0,
    parent_uuid: "",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemNameToDelete, setItemNameToDelete] = useState("");
  const [selectedTopicPath, setSelectedTopicPath] = useState(null);
  const [itemType, setItemType] = useState("as");
  const [draggedItem, setDraggedItem] = useState(null);
  const [isLastItemHovered, setIsLastItemHovered] = useState(false);
  const [checkLesson, setCheckLesson] = useState(null);
  const [rewardsTicketsData, setRewardsTicketsData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const handleSetTicketData = (uuid, value) => {
    setRewardsTicketsData((prevData) => ({
      ...prevData,
      [uuid]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/admin/v1/learn/direction?cacheBust=${new Date().getTime()}`);
      setData(response.data);
    } catch (error) {
      alert("Ошибка при получении данных. ", error);
    }
  };

  const fetchNodeData = async (uuid) => {
    try {
      const response = await axios.get(`/api/admin/v1/learn/direction?uuid=${uuid}&cacheBust=${new Date().getTime()}`);
      return response.data;
    } catch (error) {
      alert("Ошибка при получении данных. ", error);
      return [];
    }
  };

  const fetchRewardsTicketsData = async () => {
    try {
      const cacheBust = new Date().getTime();
      const response = await axios.get(`/api/admin/v1/topicMap/rewards?cacheBust=${cacheBust}`);

      if (response.status === 200) {
        setRewardsTicketsData(response.data); // Сохраняем данные вознаграждений в state
        return response.data;
      } else {
        alert("Не удалось получить данные: " + response.statusText);
        return null;
      }
    } catch (error) {
      alert("Ошибка при получении данных. ", error);
      return null;
    }
  };

  const toggleChildren = async (uuid, type) => {
    const updateItems = async (items, parentType) => {
      return Promise.all(
        items.map(async (item) => {
          if (item.uuid === uuid) {
            const children = item.isOpen ? item.childs : await fetchNodeData(uuid);
            
            // Проверка на тип "topic" и вызов fetchRewardsTicketsData
            if (item.type === "chapter" && !item.isOpen) {
              const rewardsData = await fetchRewardsTicketsData();
              // console.log("Rewards Data: ", rewardsData);
            }

            return { ...item, isOpen: !item.isOpen, childs: children };
          } else if (item.type === parentType) {
            return { ...item, isOpen: false };
          } else if (item.childs && item.childs.length > 0) {
            return {
              ...item,
              childs: await updateItems(item.childs, type),
            };
          }
          return item;
        })
      );
    };

    const updatedData = await updateItems(data, type);
    setData(updatedData);
    setSelectedUuid(uuid);
    setSelectedType(type);
  };

  const closeAllTopicItems = (items) => {
    return items.map((item) => {
      if (item.type === 'topic') {
        return { ...item, isOpen: false };
      } else if (item.childs) {
        return { ...item, childs: closeAllTopicItems(item.childs) };
      }
      return item;
    });
  };

  const handleDelete = (uuid) => {
    const item = findItem(data, uuid);
    if (item) {
      setItemToDelete(uuid);
      setItemNameToDelete(item.name + (item.name2 ? ' / ' + item.name2 : ''));
      setShowConfirmation(true);
    }
  };

  const findItem = (items, uuid) => {
    for (const item of items) {
      if (item.uuid === uuid) {
        return item;
      }
      if (item.childs && item.childs.length > 0) {
        const found = findItem(item.childs, uuid);
        if (found) return found;
      }
    }
    return null;
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`/api/admin/v1/learn/direction/${itemToDelete}`);
      setData(prevData => {
        const removeItem = (items, uuid) => {
          return items
            .filter(item => item.uuid !== uuid)
            .map(item => ({
              ...item,
              childs: item.childs ? removeItem(item.childs, uuid) : []
            }));
        };
        return removeItem(prevData, itemToDelete);
      });
      setShowConfirmation(false);
      setItemToDelete(null);
      setItemNameToDelete("");
      setSelectedTopicPath(null);
      updateCheckLesson(itemToDelete, 'none');
    } catch (error) {
      console.error("Error deleting item:", error.response.data.message);
      if (error.response.status === 409) {
        alert(`${itemNameToDelete} содержит дочерние элементы, пожалуйста, сначала удалите их. `);
      } else {
        alert("Ошибка при удалении элемента. ", error.response.data.message);
      }
    }
  };

  const handlSelectionTopicType = (type) => {
    let newItemType;
    switch (type) {
      case "grade":
        newItemType = "предмет";
        break;
      case "subject":
        newItemType = "главу";
        break;
      case "chapter":
        newItemType = "тему";
        break;
      default:
        newItemType = "";
    }
    setItemType(newItemType);
  };

  const handleHover = (item) => {
    if (item.uuid === selectedUuid) {
      return (
        <span draggable={false} onDragStart={(e) => e.stopPropagation()} className={styles.categoryBtn}>
          <EditItemName draggable={false} onDragStart={(e) => e.stopPropagation()} className={styles.icon} item={item} updateItem={updateItem} />
          {item.type !== "topic" && (
            <span className={styles.icon + " " + styles.plus} onClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
            }}></span>
          )}
          <span onClick={() => handleDelete(item.uuid)} className={styles.icon + " " + styles.delete}></span>
        </span>
      );
    }
    return null;
  };

  const dragStartHandler = (e, item) => {
    if (e.target.getAttribute("draggable") === "true") {
      setDraggedItem(item);
      e.dataTransfer.setData("application/reactflow", "drag-item");
      e.dataTransfer.effectAllowed = "move";
      e.target.style.opacity = "0.4";
    } else {
      e.preventDefault();
    }
  };

  const dragLeaveHandler = (e, item) => {
    e.target.classList.remove(styles.beforeDrop);
    e.target.classList.remove(styles.dropIn);
  };

  const dragEndHandler = (e) => {
    e.target.style.opacity = "";
    e.target.classList.remove(styles.beforeDrop);
    e.target.classList.remove(styles.dropIn);
    
  };

  const dragOverHandler = (e, item) => {
    e.preventDefault();
    if (
      draggedItem &&
      item.parent_uuid === draggedItem.parent_uuid &&
      draggedItem.uuid !== item.uuid
    ) {
      if (item.type !== "grade") {
        e.target.classList.add(styles.beforeDrop);
      }
    }

    const parentType = findParentType(data, draggedItem.parent_uuid);

    if (draggedItem && draggedItem.uuid !== item.uuid && parentType === item.type && draggedItem.parent_uuid !== item.uuid) {
        e.target.classList.add(styles.dropIn);
    }
  };

  const dragOverLastItemHandler = (e, item) => {
    if (item.uuid === draggedItem.parent_uuid) {
      e.target.classList.add(styles.beforeDrop);
    }
  };

  const findParentType = (items, parentUuid) => {
    if (!Array.isArray(items)) return null;
  
    for (const item of items) {
      if (item.uuid === parentUuid) {
        return item.type;
      }
      if (Array.isArray(item.childs) && item.childs.length > 0) {
        const found = findParentType(item.childs, parentUuid);
        if (found) return found;
      }
    }
    return null;
  };

  const dropHandler = async (e, targetItem, isLastElement = false) => {
    e.preventDefault();
    e.target.classList.remove(styles.beforeDrop);
    e.target.classList.remove(styles.dropIn);
    // console.log(targetItem);
    // console.log(draggedItem.uuid);
    // console.log(draggedItem.type);
    // console.log(targetItem.type);

    // Проверяем, если draggedItem и targetItem находятся в одном родительском элементе
    if (draggedItem.parent_uuid === null) {
      // console.log(draggedItem.parent_uuid);
      return;
    }

    // Находим тип родителя draggedItem
    const parentType = findParentType(data, draggedItem.parent_uuid);

    // Проверка: можно ли переместить draggedItem в targetItem по типу
    if (draggedItem && draggedItem.uuid !== targetItem.uuid && parentType === targetItem.type && draggedItem.parent_uuid !== targetItem.uuid) {
      const updatedData = {
        parent_uuid: targetItem.uuid,
        name: draggedItem.name,
        uuid: draggedItem.uuid,
      };

      try {
        // Отправляем PUT запрос для обновления родителя draggedItem
        const response = await axios.put(`/api/admin/v1/learn/direction?cacheBust=${new Date().getTime()}`, updatedData);
        if (response && response.data) {
          // Дополнительно отправляем POST запрос для изменения порядка перемещаемого элемента в конец списка
          try {
            const reorderResponse = await axios.post(`/api/admin/v1/learn/direction/${draggedItem.uuid}/reorder`);
            if (reorderResponse && reorderResponse.data) {
              // Вызов fetchNodeData для получения обновленных данных
              const updatedChildren = await fetchNodeData(draggedItem.parent_uuid);
              setData((prevData) => {
                const updateItems = (items) => {
                  return items.map((item) => {
                    if (item.uuid === draggedItem.parent_uuid) {
                      return { ...item, childs: updatedChildren }; // Обновляем дочерние элементы
                    } else if (item.childs) {
                      return { ...item, childs: updateItems(item.childs) };
                    }
                    return item;
                  });
                };
                return updateItems(prevData);
              });
            }
          } catch (reorderError) {
            alert("Ошибка при перемещении элемента (reorder). ", reorderError);
          }
        }
      } catch (error) {
        alert("Ошибка при перемещении элемента (update). ", error);
      }
      return;
    }

    isLastElement = targetItem.uuid === draggedItem.parent_uuid;

    // Проверка для перемещения элемента в конец списка или перед другим элементом
    if (draggedItem && draggedItem.uuid !== targetItem.uuid) {
      let apiUrl;
      if (isLastElement) {
        apiUrl = `/api/admin/v1/learn/direction/${draggedItem.uuid}/reorder`;
      } else if (targetItem.parent_uuid === draggedItem.parent_uuid) {
        apiUrl = `/api/admin/v1/learn/direction/${draggedItem.uuid}/reorder/?before_uuid=${targetItem.uuid}`;
      }

      if (apiUrl) {
        try {
          const response = await axios.post(apiUrl);
          if (response && response.data) {
            updateStateWithNewOrder(draggedItem.parent_uuid, response.data);     
          }
        } catch (error) {
          alert("Ошибка при перемещении элемента. ", error);
        }
      }
    }

    setIsLastItemHovered(false);
  };

  const updateStateWithNewOrder = (parentUuid, newOrder) => {
    setData((prevData) => {
      const updateItems = (items) => {
        return items.map((item) => {
          if (item.uuid === parentUuid) {
            return { ...item, childs: newOrder };
          } else if (item.childs) {
            return { ...item, childs: updateItems(item.childs) };
          }
          return item;
        });
      };
      return updateItems(prevData);
    });
  };

  const renderNestedList = (items, path = []) => {
    return (
      <ul className={path.length === 0 ? styles.gradesList : ""}>
        {items.map((item) => {
          const newPath = [...path, item];
          if (selectedTopicPath) {
            const isPartOfSelectedPath = selectedTopicPath.some((selectedItem) => selectedItem.uuid === item.uuid);
            if (!isPartOfSelectedPath && item.type !== "grade") {
              return null;
            }
          }

          // Проверяем, есть ли совпадение UUID с данными вознаграждений
          const ticketData = rewardsTicketsData[item.uuid];

          return (
            <li key={item.uuid} className={path.length === 0 ? styles.gradeItem : ""}>
              <div className={styles.itemsInnerContainer}>
                {ticketData && (
                  <img src={ticketIcon} alt="Ticket icon" className={styles.ticketIcon} />
                )}
                {(item.check_lesson && item.check_lesson === 'full') && <span className={`${styles.topicCheckbox} ${styles.checked}`}></span>}
                {item.check_lesson && item.check_lesson !== 'full' && <span className={`${styles.topicCheckbox} ${styles.unchecked}`}></span>}
                <span
                  onDragStart={(e) => dragStartHandler(e, item)}
                  onDragEnd={(e) => dragEndHandler(e)}
                  onDragOver={(e) => dragOverHandler(e, item)}
                  onDragLeave={(e) => dragLeaveHandler(e)}
                  onDrop={(e) => dropHandler(e, item)}
                  draggable={true}
                  className={
                    item.isOpen
                      ? `${styles.gradeName} ${styles.gradeNameOpen}`
                      : styles.gradeName
                  }
                  onClick={() => {
                    handlSelectionTopicType(item.type);
                    if (item.type === "topic") {
                      toggleChildren(item.uuid, item.type);
                      setSelectedUuid(item.uuid);
                      setCheckLesson(item.check_lesson);
                      if (!item.isOpen) {
                        setSelectedTopicPath(newPath);
                      }
                      if (item.isOpen) {
                        setSelectedTopicPath(null);
                      }
                    } else {
                      toggleChildren(item.uuid, item.type);
                      if (item.type !== "topic") {
                        setData((currentData) => closeAllTopicItems(currentData));
                        setSelectedTopicPath(null);
                      }
                    }
                  }}
                >
                  {item.name + (item.type === 'topic' ? ' / ' + item.name2 : '')}
                </span>
                {handleHover(item)}
              </div>
              {item.isOpen &&
                Array.isArray(item.childs) &&
                item.childs.length > 0 && (
                  <div className={`${styles.nestedList} ${item.isOpen ? styles.nestedListOpen : ""}`}>
                    {renderNestedList(item.childs, newPath)}
                    {!selectedTopicPath && (
                      <p
                        className={styles.dropLastArea}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => dragOverLastItemHandler(e, item)}
                        onDragLeave={(e) => e.target.classList.remove(styles.beforeDrop)}
                        onDrop={(e) => dropHandler(e, item, true)}
                      ></p>
                    )}
                  </div>
                )}
            </li>
          );
        })}
      </ul>
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }; 
  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newType;
    switch (selectedType) {
      case "grade":
        newType = "subject";
        setItemType("предмет");
        break;
      case "subject":
        newType = "chapter";
        setItemType("главу");
        break;
      case "chapter":
        newType = "topic";
        setItemType("тему");
        break;
      default:
        newType = "";
    }

    try {
      const completeFormData = {
        ...formData,
        parent_uuid: selectedUuid,
        type: newType,
      };
      const response = await axios.post("/api/admin/v1/learn/direction", completeFormData);
      if (response && response.data) {
        const newItem = {
          ...response.data,
          isOpen: false,
        }
        setData((prevData) => {
          const updateData = (items) => items.map((item) => {
            if (item.uuid === selectedUuid) {
              if (!item.childs) item.childs = [];
              item.childs.push(newItem);
              return { ...item, isOpen: true };
            } else if (item.childs) {
              return { ...item, childs: updateData(item.childs) };
            }
            return item;
          });

          return updateData(prevData);
        });
      }
      setFormData({
        name: "",
        type: "",
        parent_uuid: "",
      });
      setShowModal(false);
    } catch (error) {
      alert("Ошибка при отправке формы. ", error);
    }
  };

  const updateItem = (updatedData) => {
    setData(prevData => {
      const updateItems = (items) => {
        return items.map(item => {
          if (item.uuid === updatedData.uuid) {
            return { ...item, name: updatedData.name, name2: updatedData.name2 };
          } else if (item.childs) {
            return { ...item, childs: updateItems(item.childs) };
          }
          return item;
        });
      };
      return updateItems(prevData);
    });
  };

  const updateCheckLesson = (uuid, newStatus) => {
    setData(prevData => {
      const updateItems = (items) => {
        return items.map(item => {
          if (item.uuid === uuid) {
            return { ...item, check_lesson: newStatus };
          } else if (item.childs) {
            return { ...item, childs: updateItems(item.childs) };
          }
          return item;
        });
      };
      return updateItems(prevData);
    });
  };

  return (
    <div className={styles.EducationalContentContainer}>
      <div className="container">
        {data && data.length > 0 && renderNestedList(data)}
        {showModal && (
          <ModalHashtag isOpen={showModal} onClose={() => {setShowModal(false); setFormData({
            name: "",
            type: "",
            parent_uuid: "",
          })} }>
            <form className={styles.categoryForm} onSubmit={handleSubmit}>
              <input
                className={styles.listInput}
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={`Введите ${itemType}`}
                required
              />
              {itemType === 'тему' && (<input
              className={styles.listInput}
              type="text"
              name="name2"
              value={formData.name2}
              onChange={handleChange2}
              placeholder={'Тема регионального компонента'}
              required
            />)}
              <button className={styles.formSaveConfirmBtn} type="submit">
                Сохранить
              </button>
            </form>
          </ModalHashtag>
        )}
        {selectedTopicPath && <Lesson directionUuid={selectedUuid} checkLesson={checkLesson} updateCheckLesson={updateCheckLesson} ticketData={rewardsTicketsData[selectedUuid]}
            setTicketData={(value) => handleSetTicketData(selectedUuid, value)} />}
        <ConfirmationModal
          isOpen={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          onConfirm={confirmDelete}
        >
          <p>Вы уверены, что хотите удалить {itemNameToDelete}?</p>
        </ConfirmationModal>
      </div>
    </div>
  );
};

export default EducationalContent;
